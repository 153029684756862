import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import { LinkMuted } from './LinkMuted'

const Footer = () => (
  <StaticQuery
    query={footerQuery} // eslint-disable-line
    render={data => {
      const { author, authorSite, owner } = data.site.siteMetadata

      return (
        <FooterContainer>
          <FooterLinks>
            <div>
              <FooterTitle>
                Agnieszka Wojnar-Jadczyszyn
                <br />
                Psycholog Psychoterapeuta Rzeszów
                <br />
              </FooterTitle>
              <div>
                Gabinet psychoterapii poznawczo-behawioralnej i terapii schematu
                "eMOCja"
              </div>
              <div
                css={css`
                  margin-bottom: 2rem;
                `}
              >
                <FooterSubtitle>Adres</FooterSubtitle>
                <LinkMuted to="/kontakt">
                  ul. Fryderyka Szopena 7, 35-055 Rzeszów
                </LinkMuted>
                <FooterSubtitle>Telefon</FooterSubtitle>
                <a href="tel:+48665400815">+48 665-400-815</a>
                <FooterSubtitle>Email</FooterSubtitle>
                <a href="mailto:kontakt@gabinet-emocja.pl">
                  kontakt@gabinet-emocja.pl
                </a>
              </div>
            </div>
            <nav>
              <FooterUl>
                <FooterLink>
                  <LinkMuted to="/">Strona Główna</LinkMuted>
                </FooterLink>
                <FooterLink>
                  <LinkMuted to="/w-czym-pomagam/">W czym pomagam</LinkMuted>
                </FooterLink>
                <FooterLink>
                  <LinkMuted to="/terapia/">Psychoterapia</LinkMuted>
                </FooterLink>
                <FooterLink>
                  <LinkMuted to="/o-mnie/">O mnie</LinkMuted>
                </FooterLink>
                <FooterLink>
                  <LinkMuted to="/artykuly/">Artykuły</LinkMuted>
                </FooterLink>
                <FooterLink>
                  <LinkMuted to="/cennik/">Cennik</LinkMuted>
                </FooterLink>
                <FooterLink>
                  <LinkMuted to="/kontakt/">Kontakt</LinkMuted>
                </FooterLink>
              </FooterUl>
              <FooterTitle>Przydatne odnośniki</FooterTitle>
              <FooterUl>
                <FooterLink>
                  <LinkMuted to="/psycholog-rzeszow/">
                    Psycholog Rzeszów
                  </LinkMuted>
                </FooterLink>
              </FooterUl>
            </nav>
            <div
              css={css`
                grid-column-start: span 2;
                @media (max-width: 1200px) {
                  grid-column-start: initial;
                }
              `}
            >
              <FooterTitle>Psycholog Rzeszów</FooterTitle>
              Jestem psychologiem i psychoterapeutą poznawczo-behawioralnym.
              Prowadzę psychoterapię indywidualną osób dorosłych i młodzieży
              oraz konsultacje psychologiczne w Rzeszowie. Pracuję z osobami
              dorosłymi i młodzieżą głównie w obszarze depresji, zaburzeń
              lękowych, trudności w relacjach z innymi ludźmi, stresem
              pourazowym, trudnościami adaptacyjnymi, dolegliwościami
              psychosomatycznymi i niską samooceną. W najbliższym czasie będę
              informować o lokalizacji gabinetu w Rzeszowie. Jeśli potrzebujesz
              konsultacji psychologicznej lub chcesz dowiedzieć się więcej na
              temat możliwości umówienia się na wizytę proszę o kontakt.{' '}
            </div>
          </FooterLinks>
          <FooterBottom>
            {owner}&nbsp;©&nbsp;{new Date().getFullYear()},
            Created&nbsp;by&nbsp;
            <AuthorLink href={authorSite}>{author}</AuthorLink>
          </FooterBottom>
        </FooterContainer>
      )
    }}
  />
)

const FooterUl = styled.ul`
  list-style: none;
  margin-left: 0;
`

const FooterLink = styled.li`
  margin-bottom: 0.5rem;
`

const FooterLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 1rem;
  padding: 3rem 1rem;
  font-size: 0.9rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1252px;
  margin-bottom: 1.5rem;
  align-items: start;
`

const FooterBottom = styled.div`
  flex: 0 1 auto;
  font-size: 80%;
  text-align: center;
`

const AuthorLink = styled.a`
  box-shadow: none;
  text-decoration: none;
  color: inherit;
  font-weight: bold;
`

const FooterContainer = styled.footer`
  background-color: #fafafa;
`

const FooterTitle = styled.h4`
  font-size: 0.9rem;
  margin-bottom: 1rem;
  margin-top: 0;
`

const FooterSubtitle = styled.h6`
  font-size: 0.9rem;
  margin-top: 3em;
  margin-bottom: 0.5em;
  font-weight: normal;
`

export default Footer

export const footerQuery = graphql`
  query {
    site {
      siteMetadata {
        owner
        author
        authorSite
      }
    }
  }
`
